/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/** @namespace Scandiweb/Gtm/Data/Filter/getFilterDetails */
export const getFilterDetails = (filtersDetails, filterKey, filterValue) => {
    const filter = filtersDetails.find((filter) => filter.request_var === filterKey);
    const filterDetails = filter.filter_items.find((filterItem) => filterItem.value_string === filterValue);
    // ! ^^^ Sometimes filter acts buggy, for e.g price, You might select a price filter and it might disappear from filter list ...
    // ! ... and it will be removed from filtersDetails too.

    return {
        filterName: filterDetails?.label || filterValue,
        filterCategory: filter.name
    };
};

/** @namespace Scandiweb/Gtm/Data/Filter/getFiltersCategoryName */
export const getFiltersCategoryName = (filtersDetails, filterKeys) => filtersDetails
    .filter((filter) => filterKeys.indexOf(filter.request_var) >= 0)
    .map((filter) => filter.request_var);

/** @namespace Scandiweb/Gtm/Data/Filter/getFiltersCount */
export const getFiltersCount = (filters) => filters.map(
    (filter) => Object.values(filter).map( // << map over values of a key (which is array)
        (filterValues) => filterValues.length // << return the length
    )// vvv after those 2 maps we will have an array of length , so calculate the sum (num *1 is a way of converting string to int)
).reduce((sum, value) => sum + value * 1, 0);

/** @namespace Scandiweb/Gtm/Data/Filter/getIndexOfFilter */
export const getIndexOfFilter = (filters, key) => filters.map((filter) => Object.keys(filter)[0]).indexOf(key);

/** @namespace Scandiweb/Gtm/Data/Filter/getRemovedFilters */
export const getRemovedFilters = (oldFilters, currentFilters) => {
    const currentFiltersKey = Object.keys(currentFilters);
    const removedFilters = Object.keys(oldFilters).reduce((removedFilters, filterKey) => {
        // vvv If this key doesn't exist in currentFiltersKeys it means all filters for this key were removed
        if (currentFiltersKey.indexOf(filterKey) < 0) {
            return [
                ...removedFilters,
                { [filterKey]: oldFilters[filterKey] }
            ];
        }
        // If there is such key we should check all of its values (value for each key is array of IDs) and see which one is missing

        // vvv find each value for specific key
        oldFilters[filterKey].forEach((filterValue) => {
            // vvv If this value doesn't exist in currentFilters then we need to add it
            if (currentFilters[filterKey].indexOf(filterValue) > -1) {
                return;
            }

            // vvv if we don't have this key in removedFilters the it means it's initial and we need to create the key with array value
            if (getIndexOfFilter(removedFilters, filterKey) < 0) {
                removedFilters.push({ [filterKey]: [filterValue] });

                return;
            }

            // vvv If we already have this key in our removedFilters then we just need to push the value to it
            removedFilters[
                getIndexOfFilter(removedFilters, filterKey)
            ].push(filterValue);
        });

        return removedFilters;
    }, []);

    return removedFilters;
};

/** @namespace Scandiweb/Gtm/Data/Filter/getAddedFilters */
export const getAddedFilters = (oldFilters, currentFilters) => {
    const oldFiltersKey = Object.keys(oldFilters);
    const addedFilters = Object.keys(currentFilters).reduce((addedFilters, filterKey) => {
        // vvv If this key doesn't exist in oldFiltersKeys it means all filters for this key were added
        if (oldFiltersKey.indexOf(filterKey) < 0) {
            return [
                ...addedFilters,
                { [filterKey]: currentFilters[filterKey] }
            ];
        }

        // If there is such key we should check all of its values (value for each key is array of IDs) and see which one is missing

        // vvv find each value for specific key
        currentFilters[filterKey].forEach((filterValue) => {
            // vvv If this value doesn't exist in oldFilters then we need to add it
            if (oldFilters[filterKey].indexOf(filterValue) > -1) {
                return;
            }

            // vvv if we don't have this key in addedFilters the it means it's initial and we need to create the key with array value
            if (getIndexOfFilter(addedFilters, filterKey) < 0) {
                addedFilters.push(
                    { [filterKey]: [filterValue] }
                );

                return;
            }

            // vvv If we already have this key in our addedFilters then we just need to push the value to it
            addedFilters[
                getIndexOfFilter(addedFilters, filterKey)
            ].push(filterValue);
        });

        return addedFilters;
    }, []);

    return addedFilters;
};

/** @namespace Scandiweb/Gtm/Data/Filter/getFilterDifferences */
export const getFilterDifferences = (oldFilters, currentFilters) => [
    getRemovedFilters(oldFilters, currentFilters),
    getAddedFilters(oldFilters, currentFilters)
];
